.brand-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.brands__input {
  border: none;
  border-bottom: 2px solid #999;
  outline: none;
  padding: 5px 0;
  width: 90%;
  font-size: 16px;
}

.brands__input:focus {
  border-bottom: 2px solid #555;
}

.brands-display {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
  width: 100%;
  padding: 20px;
  justify-content: center;
}

.brand-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
}

.brand-item img {
  width: 100px;
  margin-bottom: 5px;
}

.brand-item span {
  text-align: center;
}
