/* Block: Search Modal */
.search-modal {
  position: fixed;
  top: 0;
  left: 50%; /* 左端を画面中央に配置 */
  transform: translateX(-50%); /* 左端を中央に配置した分、全体を左にシフト */
  width: 100%;
  max-width: 1200px;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 1000;
}

/* Element: Header within Search Modal */
.search-modal__header {
  width: 100%;
  background: white;
}

/* Element: Input container within Search Modal */
.search-modal__input-container {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 0.5px solid gray;
  padding: 10px 16px 20px 8px;
}

/* Base Element for Input */
.search-modal__input {
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 5px 10px 5px 35px;
  font-size: 16px;
  background: #f0f0f0;
  border-radius: 4px;
  border: 1px solid #eee;
  color: #575757;
}

/* Element: Close button in the Search Modal */
.search-modal__search-button {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  padding: 5px;
  margin-right: 5px;
}

/* Element: Close button in the Search Modal */
.search-modal__close-button {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  padding: 5px;
  margin-right: 5px;
}

/* Element: Container for category and brand links */
.search-modal__category-brand-container {
  width: 100%;
  background: white;
}

/* Element: Quick links in the Search Modal */
.search-modal__quick-links {
  width: 100%;
  background: white;
}

/* Element: Individual link in quick links */
.search-modal__quick-link {
  display: block;
  width: 100%;
  padding: 16px 15px;
  text-align: left;
  color: #333;
  font-size: 0.875em;
  text-decoration: none;
  border-bottom: 1px solid #eee;
  background: white;
  cursor: pointer;
}

/* Modifier for the last quick link to remove the bottom border */
.search-modal__quick-link--no-border {
  border-bottom: none;
}
