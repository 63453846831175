.stafftop {
  text-align: center;
  margin: 20px;
  max-width: 1200px;
}

.stafftop__title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.stafftop__link-container {
  margin: 10px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.stafftop__button,
.staff__button {
  padding: 20px 40px;
  background-color: #0095f6;
  color: #fff;
  border: none;
  border-radius: 4px;
  width: 100%;
  margin: 40px auto 0;
  text-align: center;
  font-weight: bold;
}
