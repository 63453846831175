.footer {
  padding: 1rem;
  text-align: center;
  margin-top: 2rem;
  position: sticky;
}

.footer__container {
  max-width: 960px;
  margin: 2rem auto 0 auto;
}

.footer__text {
  margin: 1rem 0 0.5rem 0;
  font-size: 0.875em;
  color: #707980;
}

.footer__links {
  font-size: 0.875em;
}

.footer__link {
  color: #707980;
  text-decoration: none;
  margin: 0 0.5rem;
}

.footer__link:hover {
  text-decoration: underline;
}

/* フッターをブラウザ下部に固定するための追加スタイル */
html {
  position: relative;
  min-height: 100%;
}

body {
  margin: 0;
  padding-bottom: 100px; /* フッターの高さ分の余白を確保 */
  box-sizing: border-box;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px; /* フッターの高さ */
}
