/* ----- Modal Overlay ----- */
.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

/* ----- Modal Content ----- */
.modal__content {
  width: 100%;
  max-width: 1000px;
  height: 100vh;
  padding: 60px 20px;
  overflow-y: auto;
  position: relative;
  background-color: white;
  border-radius: 8px;
  z-index: 1000;
  animation: modal__fadeIn 0.3s ease-in-out;
}

@keyframes modal__fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes modal__fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10%);
  }
}

.modal__content--fade-out {
  animation: modal__fadeOut 0.3s ease-in-out;
  animation-fill-mode: forwards;
}

/* ----- Close Button ----- */
.modal__close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.25rem;
  cursor: pointer;
}

/* ul要素のスタイル */
.modal__category-list {
  padding: 0; /* ulのデフォルトのパディングをリセット */
  margin: 0; /* ulのデフォルトのマージンをリセット */
  list-style-type: none; /* デフォルトのリストスタイルを削除 */
}

/* li要素のスタイル */
.modal__category-list li {
  padding: 14px;
  cursor: pointer;
  border-bottom: 0.5px solid #ccc; /* 薄い灰色の境界線を追加 */
}

.modal__category-list li:last-child {
  margin-bottom: 0;
}

.modal__category-list li:hover {
  background-color: #f0f0f0;
}

/* ボタン内のテキストスタイル（追加で調整が必要な場合） */
.modal__category-list li button {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
  width: 100%; /* ボタンをリストアイテム全体に広げる */
  text-align: left; /* テキストを左揃えにする */
  padding: 0;
}
