.staff-manage-post {
  margin: 20px;
}

.staff-manage-post__title {
  font-size: 1.125em;
  color: #333;
  margin-bottom: 20px;
}

.staff-manage-post__section-title {
  font-size: 0.875em;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}

.staff-manage-post__post-list {
  align-items: center;
  border-bottom: 0.5px solid #ccc;
  display: flex;
  padding: 10px;
  width: 100%;
}

.staff-manage-post__checkbox {
  margin-right: 3rem;
  transform: scale(1.5);
}

.staff-manage-post__post-link {
  margin: 10px 0;
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 2rem;
  width: 100%;
}

.staff-manage-post__arrow {
  font-size: 1.5rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.staff-manage-post__post-image {
  width: 100px;
  height: 100px;
  margin-right: 1.75rem;
  object-fit: cover;
}

.staff-manage-post__no-posts {
  font-size: 16px;
  color: #888;
  margin: 10px 0;
}

.staff-manage-post__button {
  padding: 10px 20px;
  background-color: #0095f6;
  color: #fff;
  border: none;
  border-radius: 4px;
  width: 100%;
  margin: 20px auto;
  text-align: center;
  font-weight: bold;
}

.staff-manage-post__button:hover {
  background-color: #0056b3;
}

.staff-manage-post__input {
  width: 100%;
  padding: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.staff-manage-post__active-post,
.staff-manage-post__inactive-post {
  margin-top: 20px;
}

.staff-manage-post__error {
  color: red;
  margin-top: 10px;
}

.staff-manage-post__delete {
  padding: 10px 20px;
  background-color: #ff4d4d;
  color: #fff;
  border: none;
  border-radius: 4px;
  width: 100%;
  margin: 20px auto;
  text-align: center;
  font-weight: bold;
}

.staff-manage-post__delete:hover {
  background-color: #e60000;
}
