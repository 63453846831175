/* Foundation (F) */
.privacy-section__message {
  color: green;
  text-align: center;
  margin-bottom: 10px;
  font-size: 0.875rem;
}

.privacy-section__error {
  color: red;
  text-align: center;
  margin-bottom: 10px;
  font-size: 0.875rem;
}

.privacy-section__label {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 0.875em;
}

.privacy-section__input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.privacy-section__description {
  font-size: 0.875rem;
  color: #2c2e31;
  text-align: center;
  margin-bottom: 20px;
}

.privacy-section__link {
  display: inline-block;
  width: 100%;
  padding: 10px 20px;
  cursor: pointer;
  color: #fff;
  background-color: #0095f6;
  border-radius: 4px;
  text-decoration: none;
  font-size: 0.875rem;
  text-align: center;
  font-weight: bold;
}

.privacy-section__link:hover {
  background-color: #007bbf;
}

/* Layout (L) */
.privacy-section {
  max-width: 600px;
  margin: 0 auto;
  padding: 30px 24px;
  background: #fff;
  border-radius: 8px;
  margin-top: 20px;
}

.privacy-section--password {
}
.privacy-section--email {
}
.privacy-section--username {
}
.privacy-section--reset-password {
}
.privacy-section--reset-password-confirm {
}

/* Object (O) */
.privacy-section__form-group {
  margin-bottom: 20px;
}

.privacy-section__item {
  margin-bottom: 20px;
}

/* Component (C) */
.privacy-section__title {
  font-size: 1.125rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.privacy-section__item-title {
  font-size: 1.125rem;
  margin-bottom: 5px;
  font-weight: bold;
}

.privacy-section__item-content {
  font-size: 0.875rem;
  margin-bottom: 5px;
}

.privacy-section__submit-button {
  width: 100%;
  padding: 10px 20px;
  cursor: pointer;
  color: #fff;
  background-color: #0095f6;
  border-radius: 4px;
  text-decoration: none;
  font-size: 0.875rem;
  text-align: center;
  border: none;
  margin-top: 0px;
}

.privacy-section__submit-button:hover {
  background-color: #007bbf;
}

.privacy-section__back-link {
  display: inline-block;
  margin-top: 20px;
  color: #0095f6;
  text-decoration: none;
}

.privacy-section__back-link:hover {
  text-decoration: underline;
}

/* Scope (S) - Password specific styles */
.privacy-section__password-criteria {
  font-size: 0.875rem;
  color: #555;
  margin-bottom: 20px;
}
