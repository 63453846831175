.profile-page-container {
  max-width: 100vmin;
  margin: 0 auto;
  padding: 5vmin 2vmin 2vmin 2vmin;
  text-align: center;
}

.profile-page-icon {
  width: 20vmin;
  height: 20vmin;
  border-radius: 50%;
  margin: 0 auto 2vmin auto;
}

.profile-page-info {
  text-align: center;
  margin-bottom: 2vmin;
}

.profile-page-info h2 {
  font-size: 20px;
}

.profile-page-buttons {
  display: flex;
  justify-content: center;
  margin-top: 5%;
}

.instagram-button {
  background-color: #efefef;
  color: black;
  font-weight: bold;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  transition:
    background-color 0.3s,
    transform 0.3s;
}

.instagram-button:active {
  background-color: #dcdcdc;
  transform: scale(0.95);
}

.instagram-button a:link,
.instagram-button a:visited,
.instagram-button a:hover,
.instagram-button a:active {
  color: black;
}

.profile-page-posts-container {
  margin-top: 20px;
}

.profile-page-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2px;
}

.profile-page-grid-item {
  position: relative;
  overflow: hidden;
}

.profile-page-grid-item-inner {
  position: relative;
  width: 100%;
  padding-top: 100%;
}

.profile-page-grid-item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.profile-page-grid-item p {
  margin: 5px 0 0;
  font-size: 14px;
  text-align: center;
}
