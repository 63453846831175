/* Foundation (F) */
.signup__explanation {
  color: #2c2e31;
  text-align: center;
  font-size: 0.875em;
}

.signup__error {
  color: red;
  text-align: center;
  margin-bottom: 20px;
}

/* Layout (L) */
.signup {
  width: 100%;
  margin: 0 auto;
  max-width: 400px;
  padding: 80px 40px;
}

/* Object (O) */
.signup__form-group {
  margin-bottom: 1em;
}

.signup__label {
  display: block;
  margin-bottom: 5px;
}

.signup__input {
  width: 100%;
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Component (C) */
.signup__title {
  margin-bottom: 20px;
  text-align: center;
  font-size: 1.125em;
}

.signup__btn {
  display: block;
  width: 100%;
  padding: 10px 20px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}

.signup__btn--custom {
  background-color: #2c2e31;
  color: #fff;
  border: none;
  margin-top: 40px;
}

.signup__btn--custom:hover {
  background-color: #1d1e20;
}

.signup__btn--login {
  background-color: #fff;
  color: #000;
  border: 1px solid #ccc;
  margin-top: 5px;
}

.signup__btn--login:hover {
  background-color: #f0f0f0;
}

.signup__links {
  text-align: center;
  margin-top: 20px;
  line-height: 170%;
}

.signup__link-button {
  color: blue;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}
