/* ----- Create Post Container ----- */
.create-post {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}

/* ----- Title ----- */
.create-post__title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
}

/* ----- Form Layout ----- */
.create-post__form {
  display: flex;
  flex-direction: column;
}

/* ----- Form Group ----- */
.create-post__form-group {
  margin-bottom: 15px;
}

/* ----- Labels ----- */
.create-post__label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

/* ----- Image Preview Container ----- */
.create-post__image-preview-container {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  width: 100px;
  height: 100px;
  background-color: #f0f0f0;
  border: 1px dashed #ccc;
}

.create-post__image-preview-empty {
  justify-content: center;
  align-items: center;
}

.create-post__input-container {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* 要素間をスペースで分ける */
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
  flex-wrap: nowrap;
}

/* ----- Image Styling ----- */
.create-post__image {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

/* ----- File Upload Button ----- */
.create-post__file-upload {
  display: inline-block;
  padding: 5px 16px;
  cursor: pointer;
  color: #fff;
  background-color: #0095f6;
  border-radius: 8px;
  margin-left: auto;
}

.create-post__file-upload:hover {
  background-color: #007bbf;
}

.create-post__error-message {
  color: red;
  font-size: 0.875em;
}

/* ----- Submit Button ----- */
.create-post__submit-button {
  padding: 10px 20px;
  background-color: #0095f6;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  margin: 20px auto 0;
  font-weight: bold;
  text-align: center;
}

.create-post__submit-button:hover {
  background-color: #007bbf;
}

/* ----- Back Link ----- */
.create-post__back-link {
  display: inline-block;
  margin-top: 20px;
  color: #0095f6;
  text-decoration: none;
}

/* ----- Remove Image Button ----- */
.create-post__remove-image-button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  color: red;
  font-weight: bold;
  cursor: pointer;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-post__remove-image-button:hover {
  background-color: rgba(255, 255, 255, 1);
}

.create-post__dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px; /* 必要に応じて高さを調整 */
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1000; /* 他の要素の上に表示するためにz-indexを設定 */
}
.create-post__dropdown-list li {
  padding: 10px;
  cursor: pointer;
}

.create-post__dropdown-list li:hover {
  background-color: #f0f0f0;
}

.create-post__category-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin: 5px 0;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.create-post__item-image {
  width: 100px; /* 画像のサイズ */
  height: 100px;
  object-fit: cover;
  margin-right: 10px;
}

.create-post__item-info {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.create-post__item-name {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.create-post__item-brand,
.create-post__item-category {
  font-size: 0.875rem;
  color: gray;
}

.remove-category-button,
.remove-item-button {
  margin-left: auto;
  background: none;
  border: none;
  color: red;
  font-weight: bold;
  display: inline-block;
  text-align: right;
  font-size: 1.8em;
}

.create-post__close-button {
  font-size: 1.8em;
}

.create-post__select-item {
  font-weight: bold;
}

/* ul要素のスタイル */
.modal__category-list {
  padding: 0; /* ulのデフォルトのパディングをリセット */
  margin: 0; /* ulのデフォルトのマージンをリセット */
  list-style-type: none; /* デフォルトのリストスタイルを削除 */
}

/* li要素のスタイル */
.modal__category-list li {
  padding: 14px;
  cursor: pointer;
  border-bottom: 0.5px solid #ccc; /* 薄い灰色の境界線を追加 */
}

.modal__category-list li:last-child {
  margin-bottom: 0;
}

.modal__category-list li:hover {
  background-color: #f0f0f0;
}

/* ボタン内のテキストスタイル（追加で調整が必要な場合） */
.modal__category-list li button {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
  width: 100%; /* ボタンをリストアイテム全体に広げる */
  text-align: left; /* テキストを左揃えにする */
  padding: 0;
}

.create-post__selected-category {
  padding: 14px;
  cursor: pointer;
  border-bottom: 0.5px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

/* 線の設定考える */
.create-post__selected-category:first-of-type {
  border-top: 0.5px solid #ccc;
}

.create-post__change-category-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.search-modal__category-select-title {
  font-weight: bold;
  text-align: center;
  font-size: 0.875em;
  margin: 40px auto 0px auto;
}

.search-modal__icon {
  position: absolute; /* 絶対位置でアイコンを配置。もっといいやり方ありそうだけど。*/
  left: 40px;
  width: 16px;
  height: 16px;
  fill: #575757;
}

.back-button {
  position: absolute;
  left: 0; /* 左端に固定 */
  top: 50%; /* 縦方向中央 */
  transform: translateY(-50%);
  margin-left: 8px;
  font-size: 1.125rem;
  font-weight: bold;
}

/* 「アイテムを選択」の部分 */

.createpost__item-list {
  display: flex;
  padding: 14px;
  width: 100%;
  border-bottom: 0.5px solid #ccc;
  display: flex;
  align-items: center;
  padding: 10px;
}

.createpost__item-list:first-child {
  border-top: none;
}

.createpost__item-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 1.75rem;
}

.createpost__item-info {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.createpost__item-name {
  margin-bottom: 5px;
  font-size: 0.875em;
}

.createpost__item-brand,
.createpost__item-category {
  font-size: 0.875em;
  color: gray;
}

.create-post__error-message {
  color: red;
  font-size: 0.875em;
}
