.not-found__container {
  text-align: center;
  padding: 50px;
}

.not-found__title {
  font-size: 48px;
  margin-bottom: 20px;
}

.not-found__text {
  font-size: 18px;
  margin-bottom: 20px;
}

.not-found__link {
  color: #007bff;
  text-decoration: none;
  font-size: 0.875rem;
}

.not-found__link:hover {
  text-decoration: underline;
}
