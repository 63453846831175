.post__image-slider {
  width: 100%; /* 親要素の幅に合わせる */
  max-width: 100%; /* 最大幅を768pxに設定 */
  margin: 0 auto;
  overflow: visible;
  position: relative;
}
.post-image-wrapper {
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.post-image {
  width: 100%;
  height: auto;
  object-fit: contain;
  max-width: 100%;
}

.slick-prev {
  left: 0;
  z-index: 1;
}

.slick-next {
  right: 0;
}

.slick-prev:before,
.slick-next:before {
  color: white;
  opacity: 0.75; /* デフォルトの0.75から1に変更 */
}

@media (min-width: 768px) {
  .slick-prev:before {
    font-size: 30px;
  }

  .slick-prev {
    left: 5px;
  }

  .slick-next:before {
    font-size: 30px;
  }

  .slick-next {
    right: 15px;
  }
}
