.preview-post {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.preview-post__title {
  font-size: 1.125em;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.preview-post__section {
  margin-bottom: 20px;
}

.preview-post__label {
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
}

.preview-post__images {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.preview-post__image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.preview-post__comment {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 4px;
}

.preview-post__categories,
.preview-post__items {
  list-style: none;
  padding: 0;
}

.preview-post__category,
.preview-post__item {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 5px;
}

.preview-post__submit-button,
.preview-post__back-button {
  padding: 10px 20px;
  background-color: #0095f6;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  font-weight: bold;
  width: 100%;
  text-align: center;
}

.preview-post__back-button {
  background-color: #ccc;
  margin-top: 10px;
}

.preview-post__submit-button:hover {
  background-color: #007bbf;
}

.preview-post__back-button:hover {
  background-color: #aaa;
}
