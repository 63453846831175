/* settings-containerスタイル */
.settings-container {
  width: 100%;
  margin: 0 auto;
}

/* settings-contentスタイル */
.settings-content {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  padding: 20px;
}

.content-section {
  width: 100%;
  max-width: 600px;
  background: #fff;
  padding: 20px;
  border: none;
  box-shadow: none;
  border-radius: 8px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.content-section h2 {
  margin-bottom: 1.5rem;
  text-align: center;
  font-size: 1.25rem;
}

.content-section .description {
  margin-bottom: 20px;
  text-align: center;
  font-size: 0.9em;
  color: #2c2e31;
}

.content-section h3 {
  margin-bottom: 5px;
  font-size: 0.9em;
  text-align: left;
}

.content-section form {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.content-section form > div {
  margin-bottom: 20px;
}

.content-section form label {
  display: block;
  margin-bottom: 5px;
}

.content-section form input,
.content-section form textarea,
.content-section form select {
  width: 100%;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.content-section form button {
  display: block;
  width: 100%;
  padding: 10px 20px;
  background-color: #2c2e31;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 45px;
  text-align: center;
  font-weight: bold;
  transition:
    background-color 0.3s,
    transform 0.3s;
}

.content-section form button:hover {
  background-color: #1d1e20;
}

/* profile-page-containerスタイル */
.profile-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.profile-page-icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.profile-page-info {
  margin-bottom: 20px;
}

.profile-page-info h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.profile-page-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.instagram-button,
.edit-profile-button {
  background-color: #2c2e31;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  transition:
    background-color 0.3s,
    transform 0.3s;
}

.instagram-button:hover,
.edit-profile-button:hover {
  background-color: #1d1e20;
}

.instagram-button:active,
.edit-profile-button:active {
  transform: scale(0.95);
}

.instagram-button a:link,
.instagram-button a:visited,
.instagram-button a:hover,
.instagram-button a:active {
  color: black;
}
