.header {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  max-width: 1200px;
  background-color: white; /* 背景色を追加 */
  z-index: 1000; /* ヘッダーを他の要素より前面に配置 */
}

.header__title-link {
  flex-grow: 1;
  text-align: center;
  text-decoration: none;
  color: inherit;
}

.header__title {
  font-size: 1.125em;
  margin: 0;
}

.header__search-icon {
  font-size: 1.25rem;
  display: flex;
  position: absolute;
  right: 10px;
  transform: translateY(-50%);
}

.header__toggle-button {
  position: absolute;
  left: 10px;
  background: none;
  border: none;
  cursor: pointer;
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.header__toggle-bar {
  width: 1.25rem;
  height: 1.5px;
  background-color: #333;
  transition: all 0.3s ease-in-out;
}

.header__toggle-button.open .header__toggle-bar:nth-child(1) {
  transform: translateY(6.5px) rotate(135deg);
}

.header__toggle-button.open .header__toggle-bar:nth-child(2) {
  opacity: 0;
}

.header__toggle-button.open .header__toggle-bar:nth-child(3) {
  transform: translateY(-6.5px) rotate(-135deg);
}

.header__toggle-button .header__toggle-bar:nth-child(1),
.header__toggle-button .header__toggle-bar:nth-child(3) {
  transform-origin: center;
}
