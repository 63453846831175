.app {
  max-width: 1200px;
  margin: 0 auto;
}

.app__container {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 35px;
}

/*
a{
  color: #007bff;
    text-decoration: none;
    font-size: 0.875rem;
}
*/
