.sidebar__content {
  width: 0;
  position: fixed;
  z-index: 50;
  top: 60px;
  left: 0;
  height: 100%;
  background-color: white;
  overflow-x: hidden;
  transition: width 0.5s ease;
}

.sidebar__content--open {
  width: 250px;
}

/* Overlay element */
.sidebar__overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 49;
  transition: opacity 0.3s ease;
}

.sidebar__overlay--visible {
  display: block;
  opacity: 1;
}

/* Link element */
.sidebar__link {
  display: block;
  color: #333;
  text-decoration: none;
  font-weight: bold;
  margin: 30px 10px 10px 10px;
  white-space: nowrap;
  overflow: hidden;
}

.sidebar__link:hover {
  background-color: #ddd;
}

/* .sliderow {
  display: block;
  padding: 10px;
  cursor: pointer;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
}

.sliderule__wrapper {
  transition: all 0.3s ease;
}

.sliderule__chevron--right::after {
  content: '>';
  float: right;
}

.mobile__menu__dropdown {
  background-color: #f1f1f1;
  padding-left: 20px;
} */

/* Media Queries */
@media (max-width: 767px) {
  .sidebar__content--open {
    width: 100%;
  }
}
