.griditems__item-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 0 auto;
}

.griditems__item-box {
  flex-basis: calc(33.3333% - 10px); /* 3列のグリッドを実現 */
}

.griditems__item-image-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
  background-color: #f0f0f0;
}

.griditems__item-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.griditems__item-info {
  text-align: center;
  font-size: 0.875em;
}

.griditems__item-name {
  font-weight: bold;
}

.griditems__item-price {
  color: gray;
  font-size: 0.875em;
}
