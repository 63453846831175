/* 1. 全体のコンテナ (container) 関連 */
.itemlist__container {
  background: #fff;
  border-radius: 8px;
  margin: 0 auto;
  max-width: 600px;
  padding: 20px;
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
}

/* ポスト関連 (itemlist__post-row, itemlist__post-item, itemlist__post-card, itemlist__post-image-wrapper) */
.itemlist__post-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  gap: 10px;
  margin: 0 -10px 0 0;
}

.itemlist__post-item {
  flex-basis: calc(33.3333% - 10px);
}

.itemlist__post-card {
  border: none;
}

.itemlist__post-image-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
  background-color: #f0f0f0;
}

.itemlist__post-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.itemlist__post-info {
  padding: 10px;
}

/* アイテムのグリッド表示 */
.itemlist__item-row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 0 -10px 0 0;
}

.itemlist__item-box {
  flex-basis: calc(33.3333% - 10px); /* 3列のグリッドを実現 */
}

.itemlist__item-image-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
  background-color: #f0f0f0;
}

.itemlist__item-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.itemlist__item-info {
  text-align: center;
  font-size: 0.875em;
}

.itemlist__brand-name {
  margin-bottom: 10px;
  font-size: 1em;
  color: #333;
}

.itemlist__categories {
  font-size: 0.7em;
  color: #333;
}
/* カテゴリータイトルのスタイル */
.itemlist__page-info {
  text-align: center;
}

.itemlist__page-title {
  font-size: 1.125em;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 0px;
  color: #333;
}

.itemlist__brandurl {
  color: gray;
  text-decoration: none;
  font-size: 0.875em;
}

.itemlist__brand-icon {
  width: 200px; /* アイコンのサイズを指定 */
  height: 200px; /* アイコンのサイズを指定 */
  object-fit: cover; /* 画像を中央で切り取り */
  display: block; /* 画像をブロック要素にして中央揃えしやすくする */
  margin: 5px auto 0; /* 上下に余白を追加して中央に配置 */
}

/* タブ関連 (itemlist__tabs, itemlist__tab-link) */
.itemlist__tabs {
  display: flex;
  padding-left: 0;
  justify-content: space-between;
  align-items: center;
  border-bottom: none;
}

.itemlist__tab-item {
  flex: 1; /* 各タブアイテムを均等に配置 */
}

.itemlist__tab-link {
  display: block;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  font-size: 1.125em;
  font-weight: bold;
  color: grey;
  border: none;
  box-sizing: border-box;
  position: relative;
}

.itemlist__tab-link::after {
  /* タブの下線の指定 */
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: lightgrey;
  position: absolute;
  bottom: 0;
  left: 0;
}

.itemlist__tab-link--active {
  color: black;
}

.itemlist__tab-link--active::after {
  background-color: black;
}

.itemlist__tab-link:hover {
  color: inherit;
  text-decoration: none;
}

.itemlist__tab-content {
  margin-top: 0.875rem;
}

/* メディアクエリ */
@media (max-width: 767px) {
  .itemlist__post-row {
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    margin: 0 -2px 0 -2px; /* 画面縁のマージンを調整 */
  }

  .itemlist__post-item {
    flex-basis: calc(33.3333% - 2px);
    padding: 0;
  }

  .itemlist__post-info {
    display: none;
  }

  .itemlist__container,
  .container-sm {
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin-right: 0;
    margin-left: 0;
    padding: 12px;
  }
}

.itemlist__info-content {
  text-align: center;
}

.itemlist__official-page-button {
  display: inline-block;
  background-color: #efefef;
  color: black;
  padding: 10px 20px;
  font-size: 1em;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  box-sizing: border-box;
  width: 100%;
  max-width: 768px;
  margin: 20px auto 5px auto;
}

.itemlist__title {
  font-size: 1.125em;
  color: #333;
  margin-top: 20px;
  margin-bottom: 10px;
  border-top: 1px solid #ccc;
  padding: 0.5rem;
  font-weight: bold;
}

/* メルカリの商品リスト */

.itemlist__mercari-title {
  /* font-size: 0.875em; */
  font-weight: bold;
  margin: 12px;
  color: #333;
}

.itemlist__mercari {
  display: block;
  width: 100%;
}

.itemlist__item-card {
  width: 100%;
  border-bottom: 0.5px solid #ccc;
  display: flex;
  align-items: center;
  /* margin: 0px 0px 10px 0px; */
  position: relative;
  padding: 8px 4px;
}

.itemlist__item-card:first-of-type {
  border-top: 0.5px solid #ccc;
}

.itemlist__item-link {
  width: 64px;
  height: 64px;
  background: #eee;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.itemlist__purchase {
  padding: 12px 0px 12px 12px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.itemlist__purchase-button {
  width: auto;
  padding: 10px 20px;
  background-color: #e32b36;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
}

.itemlist__purchase-button:hover,
.itemlist__purchase-button:focus {
  background-color: #c12830;
}

.itemlist__price-status {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  text-align: left;
  flex: 1;
}

.itemlist__price {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.itemlist__yen-symbol {
  font-size: 0.7em;
  margin-right: 1.5px;
  font-weight: bold;
  color: #333;
}

.itemlist__status {
  font-size: 12px;
  color: #333;
}

.itemlist__description {
  font-size: 0.5em;
  color: #333;
}

.itemlist__comment {
  font-size: 0.875em;
}
/* .itemlist__arrow {
  font-size: 1.5rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
} */
