/* スライダーの全体コンテナ */
.postslider__container {
  display: flex;
  overflow-x: scroll;
  padding: 10px;
  white-space: nowrap;
  scrollbar-width: none; /* Firefox用 */
  -ms-overflow-style: none; /* IEとEdge用 */
}

.postslider__container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera用 */
  overflow-x: scroll;
}

.postslider__item {
  display: inline-block;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 5%;
  transition: box-shadow 0.3s ease;
  max-width: 150px;
}
.postslider__item:hover {
  transition: box-shadow 0.3s ease;
}
.postslider__item:active {
  transform: scale(0.95);
}

.postslider__item-details {
  text-align: center;
  width: 100%;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* アイテムの個別コンテナ */
.postslider__item-image-container {
  display: block;
  padding: 10px;
  box-sizing: border-box;
  width: 150px;
  height: 150px;
  position: relative;
  overflow: hidden;
}

/* アイテム画像 */
.postslider__item-image {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

/* ホバー時のアイテム画像 */
.postslider__item-image:hover {
  transform: scale(1.05);
}

.postslider__item-image:active {
  transform: scale(0.95);
}

/* アイテム詳細コンテナ */
.postslider__item-details {
  text-align: center;
  padding-top: 10px;
  font-size: 0.875em;
  color: #2c2e31;
}

.postslider__item-details:hover {
  color: gray;
}

.postslider__item-details:active {
  color: gray;
}
.postslider__item-details:focus {
  color: gray;
}
