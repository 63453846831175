.staff-manage-item {
  margin: 20px;
}

.staff-manage-item__title {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.staff-manage-item__section-title {
  font-size: 18px;
  color: #555;
  margin-top: 20px;
  margin-bottom: 10px;
}

.staff-manage-item__item-link {
  margin: 10px 0;
}

.staff-manage-item__item-link a {
  text-decoration: none;
  color: #007bff;
  font-size: 16px;
}

.staff-manage-item__item-link a:hover {
  color: #0056b3;
}

.staff-manage-item__no-items {
  font-size: 16px;
  color: #888;
  margin: 10px 0;
}

.staff-manage-item__input {
  width: 100%;
  padding: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.staff-manage-item__button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.staff-manage-item__button:hover {
  background-color: #0056b3;
}

.staff-manage-item__active-item,
.staff-manage-item__inactive-item {
  margin-top: 20px;
}

.staff-manage-item__error {
  color: red;
  margin-top: 10px;
}

.staff-manage-item__list {
  margin-top: 20px;
  max-height: 400px;
  overflow-y: auto;
}

.staff-manage-item__list-item {
  display: flex;
  justify-content: space-between; /* 横並びにする */
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.staff-manage-item__list-item:hover {
  background-color: #f0f0f0; /* ホバー時の背景色 */
}

.staff-manage-item__list-item.selected {
  background-color: #e0e0ff; /* 選択されたアイテムの背景色 */
}

.staff-manage-item__list-item-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.staff-manage-item__list-item div {
  margin-right: 10px;
}

.staff-manage-item__select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6%;
}

.staff-manage-item__selected {
  margin-top: 20px;
}

.staff-manage-item__item-image {
  width: 100px;
  height: auto;
}

.staff-manage-item__form {
  margin-top: 20px;
}

.staff-manage-item__click {
  padding: 10px 20px;
  background-color: #efefef;
  border: none;
  border-radius: 4px;
  width: 100%;
  margin: 10px auto 30px;
  text-align: center;
  /* font-weight: bold; */
}

.error {
  color: red;
}

.staff-manage-item h3 {
  font-weight: bold;
  font-size: 0.875em;
}
/* 
.stafftop__link {
  color: #0095f6;
} */
