.gridposts__post-row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.gridposts__post-item {
  flex-basis: calc(33.3333% - 10px);
}

.gridposts__post-card {
  border: none;
}

.gridposts__post-image-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
  background-color: #f0f0f0;
}

.gridposts__post-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gridposts__post-info {
  padding: 10px;
}

/* 6. ユーザー情報関連 (gridposts__user-info, gridposts__user-icon, gridposts__username) */
.gridposts__user-info {
  display: flex;
  align-items: center;
}

.gridposts__user-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.gridposts__username {
  margin: 0;
  font-size: 1em;
}

/* 7. メディアクエリ */
@media (max-width: 767px) {
  .gridposts__post-row {
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    width: 100%;
  }

  .gridposts__post-info {
    display: none;
  }

  .gridposts__post-item {
    flex-basis: calc(33.3333% - 2px);
  }
}
