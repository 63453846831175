.staff-manage-user {
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f9;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  margin: 50px auto;
}

.staff-manage-user__title {
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
  font-weight: bold;
}

.staff-manage-user__input {
  width: 80%;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.staff-manage-user__button {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.staff-manage-user__button:hover {
  background-color: #218838;
}

.staff-manage-user__message {
  margin-top: 20px;
  font-size: 16px;
  line-height: 1.5;
}

.staff-manage-user__message.success {
  color: #28a745;
}

.staff-manage-user__message.error {
  color: #dc3545;
}

.staff-manage-user__profile-link {
  display: inline-block;
  margin-top: 20px;
  color: #007bff;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  transition: color 0.3s ease;
}

.staff-manage-user__profile-link:hover {
  color: #0056b3;
}
