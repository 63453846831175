/* Foundation (F) */
.aboutus__description,
.aboutus__section-description,
.aboutus__list,
.privacy-policy__section-description,
.privacy-policy__list-item,
.disclaimer__description,
.contact__description {
  font-size: 0.875rem;
  color: #333;
  line-height: 1.6;
}

/* Layout (L) */
.aboutus,
.privacy-policy,
.disclaimer,
.contact {
  max-width: 800px;
  margin: 0 auto;
  padding: 30px 24px;
}

/* Object (O) */
.aboutus__section,
.privacy-policy__section {
  margin: 3em 0;
}

/* Component (C) */
.aboutus__title,
.aboutus__section-title,
.privacy-policy__title,
.privacy-policy__section-title,
.disclaimer__title,
.contact__title,
.contact__section-title {
  font-size: 1.125em;
  color: #2c2e31;
  font-weight: 600;
  margin: 3em 0 1em 0;
}

.aboutus__links a,
.privacy-policy__section-description a {
  color: #007bff;
  text-decoration: none;
  font-size: 0.875rem;
}
