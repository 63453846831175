.category-search {
  width: 100%; /* or specific width as needed */
}

.category-search__item {
  padding: 10px;
  border-bottom: 1px solid #ccc; /* underline each category */
  margin: 5px 0; /* add some spacing */
}

.category-search__link {
  display: block;
  width: 100%;
  text-align: left;
  color: #333;
  text-decoration: none;
  background: white;
  cursor: pointer;
}

.category-search__back-button {
  position: absolute;
  left: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #333;
}

.category-search__container {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
}

.category-search__title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.category-search__input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.category-search__item {
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}
