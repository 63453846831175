.edit-profile-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}

.edit-profile-title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
}

.edit-profile-container form {
  display: flex;
  flex-direction: column;
}

.edit-profile-container form > div {
  margin-bottom: 15px;
}

.edit-profile-container form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.edit-profile-container .profile-image-section {
  display: flex;
  align-items: center;
}

.edit-profile-container .profile-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between; /* 要素間をスペースで分ける */
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
}

.edit-profile-container .current-profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.edit-profile-container .username-display {
  margin-right: auto;
  margin-left: 10px;
  font-weight: bold;
}

.edit-profile-container input[type='file'] {
  display: none;
}

.edit-profile-container .custom-file-upload {
  display: inline-block;
  padding: 5px 16px;
  cursor: pointer;
  color: #fff;
  background-color: #0095f6;
  border-radius: 8px;
  margin-left: auto; /* 右寄せ */
}

.edit-profile-container .custom-file-upload:hover {
  background-color: #007bbf;
}

.edit-profile-container form input,
.edit-profile-container form textarea,
.edit-profile-container form select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.edit-profile-container .submit-button {
  padding: 10px 20px;
  background-color: #0095f6;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  margin: 20px auto 0; /* 中央揃え */
  font-weight: bold;
}

.edit-profile-container .submit-button:hover {
  background-color: #007bbf;
}

.back-link {
  display: inline-block;
  margin-top: 20px;
  color: #0095f6;
  text-decoration: none;
}
