/*全体設定*/
.post__container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0px 0 20px 0;
  justify-content: center;
}

.post__container > div {
  width: 100%;
}

/*投稿写真・いいねボタンやユーザーネームなど*/
.post__container p {
  color: #555555;
}
/*投稿写真・いいねボタンやユーザーネームなど*/
.post__image-container {
  width: 100%;
  margin-bottom: 0;
  max-width: 768px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0 auto;
}
.post__image {
  width: 100%;
  height: auto;
  display: block;
}

.post__below {
  padding: 0 1rem 0.5rem 1rem;
  max-width: 768px;
  margin: 0 auto;
}

.post__user-info {
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  justify-content: space-between;
}
.post__user-info a {
  text-decoration: none;
}
.post__user-name {
  color: #888888;
  font-size: 1.125em;
  text-decoration: none;
}

/*いいねボタン*/
.post__btn-favorite {
  background: none;
  border: none;
  padding: 0;
  outline: none;
  box-shadow: none;
  transition: color 3s ease;
  font-size: 1.5em;
}

.post__btn-favorite--active {
  color: red;
}

.post__category-link {
  display: inline-block;
  padding: 0px 10px;
  margin: 5px;
  border: 1px solid #999999;
  color: #999999;
  text-decoration: none;
}

.post__category-link:hover {
  background-color: #999999;
  color: #ffffff;
}

.post__edit-section {
  width: 100%;
  display: flex;
  max-width: 768px;
}

.post__edit-button {
  padding: 10px 20px;
  margin: 1rem;
  width: 100%;
  background-color: #efefef;
  border: none;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
}

/*使用アイテム関連*/
.post__used-item {
  width: 100%;
  border-top: 1px solid #ccc;
  margin-bottom: 1rem; /*上と揃えるなら本来は0.5rem*/
}
.post__item-title {
  margin: 20px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: bold;
  color: black;
}
.post__item-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px;
}
.post__item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  margin-bottom: 20px;
}
.post__item-image-container {
  width: 100%;
}

/*関連投稿*/
.post__same-item {
  width: 100%;
  text-align: center;
  border-top: 1px solid #ccc;
}
.post__same-item-title {
  width: 100%;
  margin: 20px 0 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: bold;
  color: black;
}
.post__relevant-posts {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  gap: 1px;
}
.post__relevant-post {
  display: block;
  width: calc(33.33% - 2px);
  position: relative;
}
.post__relevant-post::before {
  content: '';
  display: block;
  padding-top: 100%;
}
.post__relevant-post-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*レスポンシブ対応*/
@media (max-width: 768px) {
  .post__relevant-posts {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1px;
  }
  .post__relevant-post {
    width: 100%;
  }
}

/* Slider.css に追加する例 */
.slick-slide {
  width: 33.33%;
  box-sizing: border-box;
}

.slick-track {
  display: flex;
  width: 100%;
}

.slick-list {
  overflow: hidden;
}

.slick-slide > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
