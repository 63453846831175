/* 1. 全体のコンテナ (container) 関連 */
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
}

/* 2. タブ関連 (home__tabs, home__tab-link) */
.home__tabs {
  display: flex;
  padding-left: 0;
  justify-content: space-between;
  align-items: center;
  border-bottom: none;
}

.home__tab-item {
  flex: 1; /* 各タブアイテムを均等に配置 */
}

.home__tab-link {
  display: block;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  font-size: 1.125em;
  font-weight: bold;
  color: grey;
  border: none;
  box-sizing: border-box;
  position: relative;
}

.home__tab-link::after {
  /* タブの下線の指定 */
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: lightgrey;
  position: absolute;
  bottom: 0;
  left: 0;
}

.home__tab-link--active {
  color: black;
}

.home__tab-link--active::after {
  background-color: black;
}

.home__tab-link:hover {
  color: inherit;
  text-decoration: none;
}

.home__tab-content {
  margin-top: 0.875rem;
  justify-content: center;
}

/* ブランド、カテゴリー関連 */

.home__brands {
  display: flex;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  flex-direction: column;
  margin-bottom: 30px;
}

.home__brands__slider {
  display: flex;
  gap: 10px;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.home__categories {
  display: flex;
  gap: 15px;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.home__categories::-webkit-scrollbar,
.home__brands__slider::-webkit-scrollbar {
  display: none;
}

.home__brand-box,
.home__category-box {
  min-width: 100px;
  height: fit-content;
  padding: 0px;
  background-color: #fff;
  text-align: center;
  box-sizing: border-box;
}

.home__brand-box:hover,
.home__category-box:hover {
  background-color: #f4f4f4;
}

.home__brand-name,
.home__category-name {
  font-size: 0.75em;
  color: gray;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* メディアクエリ */
@media (max-width: 767px) {
  .container,
  .container-sm {
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin-right: 0;
    margin-left: 0;
  }

  .home__brand-box,
  .home__category-box {
    min-width: 100px;
    height: fit-content;
    padding: 0px;
    background-color: #fff;
    text-align: center;
    box-sizing: border-box;
  }
}

/* 既存の投稿表示用のスタイルと同様にグリッド表示を設定します */

/* カテゴリータイトルのスタイル */

.home__brand-icon {
  width: 50%;
  height: 50%;
  border-radius: 10%; /* 丸くする */
  object-fit: cover; /* 画像を中央で切り取り */
  display: block; /* 画像をブロック要素にして中央揃えしやすくする */
  margin: 0px auto; /* 上下に余白を追加して中央に配置 */
  aspect-ratio: 1;
}

.home__item-price {
  color: gray;
  font-size: 0.875em;
}

.home__page-info {
  text-align: center;
}

.home__page-title {
  font-size: 1.125em;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 0px;
  color: #333;
}

.home__brandurl {
  color: gray;
  text-decoration: none;
  font-size: 0.875em;
}

.home__breadcrumb {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.home__breadcrumb-link {
  text-decoration: underline;
  color: #333;
  margin-left: 5px;
}

.home__breadcrumb-separator {
  margin-right: 5px;
  color: #666;
}

.home__allbrands {
  margin-right: 10px;
  margin-left: auto;
  font-size: 0.875em;
  color: #007bff;
}
